<template>
  <div>
    <ed-table
    :key="$root.$session.versao"
      strModel="Cadastro/Profissional"
      :headers="arrayHeaders"
      :rows="arrayRows"
      :pagination="objPagination"
      :filter="formFiltros"
      :loading="loading"
      @register="onRegister"
      @edit="onEdit"
      @search="getFuncao"
    />
  </div>
</template>

<script>
import { EdTable } from "@/components/common/form";

export default {
  name: "pageCadastroEmpresa",
  props: {},
  components: { EdTable },
  beforeDestroy() {
    this.arrayHeaders = [];
    this.arrayRows = [];
    this.objPagination = null;
  },
  mounted() {
    this.getFuncao();
  },
  created() {},
  data() {
    return {
      loading: false,
      arrayHeaders: [
        {
          text: "Nome",
          sortable: true,
          value: "strNome",
        },
        {
          text: "Tipo",
          sortable: true,
          value: "intTipoProfissionalId",
        },
        {
          text: "Documento",
          sortable: true,
          value: "strDocumento",
        },
      ],
      arrayRows: [],
      objPagination: null,
      formFiltros:{}
    };
  },
  methods: {
    onRegister() {
      this.$router.push({ name: "cadastro.profissional.register" });
    },

    onEdit(data) {
      this.$router.push({
        name: "cadastro.profissional.edit",
        params: { intId: data.intId },
      });
    },

    getFuncao() {
      this.loading = true;
      this.arrayRows = [];

      this.$root.$request
        .get("Cadastro/Profissional", this.formFiltros)
        .then((objResult) => {
          if (objResult.status == 200) {
            this.objPagination = objResult.result;
            this.loading = false;

            let arrayRows = [];

            objResult.result.data.forEach((objProfissional) => {
              let item = {
                intId: objProfissional.intId,
                strNome: objProfissional.strNome,
                strDocumento:(objProfissional.strDocumento ? objProfissional.strDocumento +
                  ( objProfissional.strDocumentoUf ?
                  "/" +
                  objProfissional.strDocumentoUf : "") : "---"),
                intTipoProfissionalId: objProfissional.tipo_profissional
                  ? objProfissional.tipo_profissional.strNome
                  : "---",
                _item: objProfissional,
              };

              arrayRows.push(item);
            });

            this.arrayRows = arrayRows;
          }
        });
    },
  },
};
</script>
